<template>
  <en-drawer :model-value="modelValue" title="会员充值" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="业务类型">
        <select-maintain
          v-model="form.data.type"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['TOPUPTYPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          disabled
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="客户编号">
        <en-input :model-value="form.data.customer?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="客户名称">
        <en-input :model-value="form.data.customer?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="手机号">
        <en-input :model-value="form.data.customer?.cellphone" disabled></en-input>
      </en-form-item>
      <en-form-item label="拥有车辆">
        <en-input :model-value="form.data.customer?.ownedVehicles.map((item) => item.plateNo).join(',')" disabled></en-input>
      </en-form-item>
      <en-form-item label="会员卡号">
        <en-input :model-value="form.data.customer?.membershipCardNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="当前可用余额">
        <en-input :model-value="formatMoney(form.data.customer?.accountInfo?.chargeable)" disabled></en-input>
      </en-form-item>
      <en-form-item label="本次充值金额" prop="chargeableAmount">
        <en-input-number v-model="form.data.chargeableAmount" :min="0" :precision="2" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="实际收款金额" prop="paymentAmount">
        <en-input-number v-model="form.data.paymentAmount" :min="0" :precision="2" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="销售人员">
        <select-maintain
          v-model="form.data.salesman"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          remote
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const formDataInit = (): EnocloudCommonDefinitions['TopupDto'] => {
  return {
    type: { code: 'TOP', message: '充值', description: '', type: '' },
    chargeableAmount: 0,
    paymentAmount: 0
  }
}

export default factory({
  props: {
    modelValue: Boolean,
    customer: Object as PropType<Record<string, any> | undefined>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.data.customer = this.customer as EnocloudCommonDefinitions['CustomerDto']
        } else {
          this.form.data = formDataInit()
        }
      }
    }
  },

  config: {
    children: {
      tabs: {
        active: 'vehicle'
      },
      form: {
        data: formDataInit(),
        rules: {
          chargeableAmount: [{ required: true, message: '请输入本次充值金额' }],
          paymentAmount: [{ required: true, message: '请输入实际收款金额' }]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/common/customer/topup',
            validate: true,
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.form.submit()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
